import { Component, NgZone } from '@angular/core';
import { environment } from '../environments/environment';
import { Capacitor } from '@capacitor/core';
import { Router, NavigationStart, NavigationEnd, GuardsCheckStart } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { LangService } from './services/lang.service';
import { GeneralService } from './services/general.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { Market } from '@ionic-native/market/ngx';
import { CartService } from './services/cart.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';
import { Geolocation } from '@capacitor/geolocation';
import { filter } from 'rxjs/operators';
import { StorageService } from './services/storage.service';
import { Accessibility } from 'accessibility';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  /**
   * 
   * @description
   * This array is used to display the menu in the side menu
   */
  public appPages = [
    { titleEn: 'Home', titleAr: 'الصفحة الرئيسية', icon: 'home', svg: true, url: '' },
    { titleEn: 'My account', titleAr: 'حسابي', icon: 'qr-code-outline', svg: false, url: 'account' },
    { titleEn: 'My orders', titleAr: 'طلباتي', icon: 'orders', svg: true, url: 'orders' },
    { titleEn: 'My subscribtions', titleAr: 'إشتراكاتي', icon: 'subscribe', svg: true, url: 'subscriptions' },
    { titleEn: 'Discounts', titleAr: 'الخصومات', icon: 'discounts', svg: true, url: 'discounts' },
    { titleEn: 'Favorites', titleAr: 'المفضلة', icon: 'heart-outline', svg: false, url: 'favorites' },
    { titleEn: 'Contact us', titleAr: 'تواصل معنا', icon: 'contact-us', svg: true, url: 'contact-us' },
    { titleEn: 'About us', titleAr: 'من نحن', icon: 'about-us', svg: true, url: 'about-us' },
    { titleEn: 'Branches', titleAr: 'الفروع', icon: 'map', svg: true, url: 'map' },
    { titleEn: 'News', titleAr: 'الأخبار', icon: 'news', svg: true, url: 'news' },
    { titleEn: 'careers', titleAr: 'إعلان وظيفي', icon: 'body', svg: false, url: 'careers' },
  ];
  websiteLink = environment.website;
  permission: boolean = false;
  splash: boolean = true;
  minOrder: number = 0;
  loading: boolean = true;
  init: boolean = true;
  isNotif: boolean = false;
  nbrOfItemSubscription = 0;

  constructor(
    private plt: Platform,
    private translate: TranslateService,
    public l: LangService,
    private services: GeneralService,
    private market: Market,
    private alert: AlertController,
    private cart: CartService,
    private router: Router,
    private zone: NgZone,
    private st: StorageService
  ) {
    console.log('App component')  
    this.router.events
    .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof GuardsCheckStart))
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 7000);
      } else if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.loading = false;
          this.init = false;
        }, 2000);
      } else if (event instanceof GuardsCheckStart) {
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
  });
  this.cart.nbrOnChangeSubscription().subscribe((nbr: number) => {
    this.nbrOfItemSubscription = nbr;
  });

    this.initialize();
  }
  async initialize() {
    
    const settings = await this.services.get('settings', false, 0, false);
    this.minOrder = settings.minOrder;
    // this.st.setItem('cartItem', this.cartItems, true, Date.now() + (3600 * 1000 * 3))
    const cartItems = await this.st.getItem('cartItem');
    const al = await this.versionShouldBeChanged(settings);
    this.getLocation();
    this.setLang();
    if (Capacitor.isNativePlatform()) {
      await this.plt.ready()
      this.OneSignalInit();
      this.listenUrlOpen();
    } else {
      const options = {
        modules: {
          decreaseText: true,
          increaseText: true,
          invertColors: true,
          increaseTextSpacing: true,
          decreaseTextSpacing: true,
          increaseLineHeight: false,
          decreaseLineHeight: false,
          grayHues: true,
          underlineLinks: false,
          bigCursor: true,
          readingGuide: true,
          textToSpeech: true,
          speechToText: true,
          disableAnimations: true
      },
      session: {
        persistent: false
      },
      textToSpeechLang: 'ar',
      speechToTextLang: 'ar'
    };
      window.addEventListener('load', function() { new Accessibility(options); }, false);
    }
    this.hideSplashScreen();
  }

/**
 * 
 * @param settings 
 * @returns 
 * @description
 * This function is used to check if the version of the app is the same as the version required by the server
 * if the version is not the same and the update is required, the user will be redirected to the store
 * if the version is not the same and the update is not required, the user will be redirected to the home page
 * if the version is the same, nothing will happen
 * if the user is not native, the page will be reloaded
 */
  async versionShouldBeChanged(settings:any) {
    const { versionRequired, updateRequired } = settings[Capacitor.getPlatform()] || settings;
    const { version } = environment;
    const isNative = Capacitor.isNativePlatform();
    if (isNative && versionRequired !== version && updateRequired) {
        const [header, message]: any = await Promise.all([
          firstValueFrom(this.translate.get('ATTENTION')),
          firstValueFrom(this.translate.get('UPDATE REQUIRED'))
        ]);
        const alert = await this.alert.create({
          header,
          message,
          backdropDismiss: false,
          cssClass: 'center-alert',
          buttons: [{
            text: 'Ok',
            handler: async () => {
              if (this.plt.is('ios')) {
                this.market.open('id1434999965');
              } else if (this.plt.is('android')) {
                this.market.open('com.almamlakasweets.almamlakasweets');
              }
              this.versionShouldBeChanged(settings);
            },
            cssClass: 'alert-button'
          }]
        });
        await alert.present();
    } else if (!isNative && versionRequired !== version && updateRequired) {
      location.reload();
    }
  }

  async getLocation() {
    if (Capacitor.isNativePlatform()) {
      const { coarseLocation, location } = await Geolocation.checkPermissions();
      if (coarseLocation === 'granted' || location === 'granted') {
        this.nativeLocation();
      } else {
        const req = await Geolocation.requestPermissions();
        if (req.coarseLocation === 'granted' || req.location === 'granted') {
          this.nativeLocation();
        }
      }
    }
  }

  setLang() {
    if (localStorage.getItem('lang')) {
      this.translate.use(this.l.get);
    } else {
      this.translate.setDefaultLang('ar');
    }
  }

  async nativeLocation() {
    try {
      const id = await Geolocation.watchPosition({
        enableHighAccuracy: true
      }, async (coordinates: any, err) => {
        if (coordinates && coordinates.coords.altitudeAccuracy < 33) {
          Geolocation.clearWatch({ id });
          localStorage.setItem('location', JSON.stringify({
            coords: coordinates.coords,
            expire: Date.now() + (60000 * 30)
          }));
        }
      });
    } catch (error) {
      console.log('error', error)
    }
  }

  listenUrlOpen() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(".com").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  async OneSignalInit() {
    this.isNotif = true;
    const APP_ID = '8e28f333-1dc3-4ad4-9bfa-301fc894a719';
    // const senderId = '7267376567';
    // OneSignal.getDeviceState(({ hasNotificationPermission,  userId}) => {
    //   console.log("e.userId", userId)
    // })
    OneSignal.setAppId(APP_ID);
    OneSignal.setLocationShared(false);
    // OneSignal.getDeviceState(({ hasNotificationPermission }) => {
    //   if (!hasNotificationPermission) {
    //     OneSignal.promptForPushNotificationsWithUserResponse(true)
    //   }
    // })
    OneSignal.addPermissionObserver((event) => {
      console.log("permission change", event);
    })
    OneSignal.setLanguage(this.l.get);
    OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent: any) => {
      notificationReceivedEvent.complete(notificationReceivedEvent.notification);
    })
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
    });
    OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // const notif = localStorage.getItem('notification');
    // if (notif !== null) {
    //   this.permission = JSON.parse(notif);
    //   OneSignal.disablePush(this.permission)
    // } else {
    //   this.permission = true;
    //   OneSignal.disablePush(true)
    // }
    // OneSignal.addSubscriptionObserver((e) => {
    //   if (!e.from.isSubscribed) {

    //   }
    //   OneSignal.getDeviceState((deviceState) => {
    //     console.log({deviceState})
    //   });
    // });
  }
  changeNotification(e: any) {
    console.log("Event", e)
    if (!e.detail.checked) {
      OneSignal.disablePush(e.detail.checked);
      this.permission = e.detail.checked;
    } else {
      OneSignal.promptForPushNotificationsWithUserResponse(true);
    }
    // 
    
    // localStorage.setItem('notification', JSON.stringify(e.detail.checked));
  }

  hideSplashScreen() {
    setTimeout(async () => {
      this.splash = false;
    }, 6000);
  }

  changeLanguage(e: any) {
    this.translate.use(e.detail.value);
    this.l.set = e.detail.value;
  }
}
